<template>
  <p-tooltip
    v-if="element.properties.tooltip"
    :text="element.properties.tooltip.text"
    :position="element.properties.tooltip.position"
  >
    <p-button
      :type="element.properties.type"
      :color-type="element.properties.color"
      :icon="element.properties.icon"
      :size="element.properties.size"
      :disabled.prop="element.properties.disabled"
      :loading.prop="loading"
      :loading-success.prop="loadingSuccess"
      @click.stop="onClick"
    >
      {{ element.properties.text }}
    </p-button>
  </p-tooltip>

  <div class="submit-button" v-else-if="element.properties.submit">
    <p-button
      :type="element.properties.type"
      :color-type="element.properties.color"
      :icon="element.properties.icon"
      :size="element.properties.size"
      :disabled.prop="element.properties.disabled"
      :loading.prop="loading"
      :loading-success.prop="loadingSuccess"
      @click.stop="onClick"
    >
      {{ element.properties.text }}
    </p-button>
  </div>

  <p-button
    v-else
    :type="element.properties.type"
    :color-type="element.properties.color"
    :icon="element.properties.icon"
    :size="element.properties.size"
    :disabled.prop="element.properties.disabled"
    :loading.prop="loading"
    :loading-success.prop="loadingSuccess"
    @click.stop="onClick"
  >
    {{ element.properties.text }}
  </p-button>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { IElementButton, TriggerType } from '@/interfaces/element';
import { Trigger } from '@/Trigger';
import { EventBus } from '@/main';
import { PropType } from 'vue';

@Component({
  name: 'layout-element-button'
})
export default class extends Vue {
  @Prop({ type: Object as PropType<IElementButton> }) public readonly element!: IElementButton;

  public loading = false;
  public loadingSuccess = false;

  created() {
    EventBus.$on('BLUEPRINT_UPDATED', this.onUpdatedListener);
  }

  beforeDestroy() {
    EventBus.$off('BLUEPRINT_UPDATED', this.onUpdatedListener);
  }

  onUpdatedListener(success: boolean) {
    if (this.loading) {
      if (success) {
        this.loadingSuccess = true;

        setTimeout(() => {
          this.loading = false;
          this.loadingSuccess = false;
        }, 1500);
      } else {
        this.loading = false;
      }
    }
  }

  @Watch('element.properties.autoClick')
  private onAutoClickChange() {
    if (this.element.properties.autoClick) {
      this.element.properties.autoClick = false;
      this.onClick();
    }
  }

  onClick() {
    // Preview multiple submits with an existing one already running
    if (this.loading || this.element.properties.disabled) {
      return;
    }

    if (this.element.properties.href) {
      this.loading = true;
    }

    if (
      !this.element.properties.name &&
      this.element.properties.trigger?.type === TriggerType.UPDATE &&
      !this.element.properties.href
    ) {
      return;
    }

    if (typeof this.element.properties.href !== 'undefined' && this.element.properties.href !== '') {
      if (
        typeof this.element.properties.target !== 'undefined' &&
        this.element.properties.target !== '' &&
        this.element.properties.target === '_blank'
      ) {
        this.loading = false;
        window.open(this.element.properties.href);
        return;
      }

      if (this.element.properties.href.indexOf('http') === -1) {
        const path = this.element.properties.href;

        const isBackendApiRoute =
          (path.includes(window.location.host) && path.includes('/api/')) || path.startsWith('/api/');

        if (isBackendApiRoute) {
          window.location.href = path;
          this.loading = false;
          return;
        }

        Trigger.handle(
          {
            type: TriggerType.NAVIGATION,
            url: path,
            action: null,
            condition: null
          },
          this.$el
        );
      } else {
        window.location.href = this.element.properties.href;
        this.loading = false;
        return;
      }
    } else if (this.element?.properties?.trigger && this.element?.properties?.trigger?.type) {
      // Show inline loading within the button if the button is responsible for a new blueprint update
      if (this.element.properties.trigger.type === TriggerType.UPDATE && !this.element.properties.trigger.condition) {
        this.loading = true;
      }
      Trigger.handle(this.element.properties.trigger, this.$el, this.element.properties.name, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/devices.scss';

@include for-mobile-only {
  .submit-button {
    align-self: center;
  }
}
</style>
