<template>
  <menu-overlay :show="show" title="Workspace" @close-request="$emit('close-request')" :body-border="false">
    <p-table
      searchable
      searchable-autofocus
      pagination
      :header="[
        {
          id: 'name',
          label: 'Name',
          width: '400px',
          sortable: true,
          verticalAlign: 'middle',
          hasPadding: true
        },
        {
          id: 'contact_name',
          label: 'Contact name',
          width: '180px',
          sortable: true,
          verticalAlign: 'middle',
          hasPadding: true
        },
        {
          id: 'contact_email',
          label: 'Contact email',
          width: '180px',
          sortable: true,
          verticalAlign: 'middle',
          hasPadding: true
        },
        {
          id: 'actions',
          verticalAlign: 'middle',
          hasPadding: true
        }
      ]"
      :body="workspacesBody"
      :total-items="workspaces?.meta?.total ?? 0"
      :skeleton-loader="loading"
      v-model="tableState"
    >
      <template #bodyCell="{ header, row, column }">
        <div v-if="header.id === 'name'" class="customer-name">{{ column }}</div>

        <p-link v-else-if="header.id === 'actions'" :href="`/api/v1/workspace/${row.uuid}/impersonate`">
          <p-button color-type="tertiary" size="small" :disabled="row.id === workSpaceStore.workspace?.id"
            >Login</p-button
          >
        </p-link>

        <template v-else>{{ column }}</template>
      </template>
    </p-table>
  </menu-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MenuOverlay from './MenuOverlay.vue';
import { AppRequest } from '@/app_request';
import { WorkspaceResource } from '@/types/api/workspace';
import { useWorkspaceStore } from '@/store/workspaceStore';
import { TableBody, TableState } from '@/components/ui/Table.vue';
import { PaginatedResult } from '@/types/api/generic';
import { debounce } from 'lodash-decorators';

@Component({
  components: {
    MenuOverlay
  }
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public readonly show!: boolean;

  public loading = true;
  public workspaces: PaginatedResult<WorkspaceResource> | null = null;
  public workSpaceStore = useWorkspaceStore();
  public tableState: TableState = {
    sorting: {
      column: '',
      direction: 'none'
    },
    searchTerm: '',
    itemsPerPage: 10,
    currentPage: 1,
    bulkSelections: []
  };

  @Watch('show')
  public onShow() {
    if (this.show) {
      this.search();
    }
  }

  @Watch('tableState.searchTerm')
  @debounce(1500)
  public onSearchTermChange() {
    this.tableState.currentPage = 1;
    this.searchDebounce();
  }

  @Watch('tableState.currentPage')
  public onPageChange() {
    this.searchDebounce();
  }

  @Watch('tableState.itemsPerPage')
  public onItemsPerPageChange() {
    this.searchDebounce();
  }

  @debounce(25)
  public searchDebounce() {
    this.search();
  }

  public async search() {
    this.loading = true;

    this.workspaces = (
      await AppRequest.get<PaginatedResult<WorkspaceResource>>('/api/v1/workspace', {
        params: {
          ...(this.tableState.searchTerm && { search: this.tableState.searchTerm }),
          ...(this.tableState.currentPage && { page: this.tableState.currentPage }),
          ...(this.tableState.itemsPerPage && { per_page: this.tableState.itemsPerPage }),
          sort: ['created_on:desc']
        }
      })
    ).data;

    this.loading = false;
  }

  public get workspacesBody(): TableBody[] {
    return (
      this.workspaces?.data.map<TableBody>((workspace) => {
        return {
          ...workspace
        };
      }) ?? []
    );
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';

::v-deep {
  .customer-name {
    @include component-text-strong;

    color: var(--text-color-headline);
  }
}
</style>
