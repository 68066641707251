<template>
  <p-form-element
    :size="size"
    :disabled="disabled"
    :label="label"
    :help-text="helpText"
    :helpInTooltip="helpInTooltip"
    :error="error"
  >
    <p-date-picker
      :model-value="modelValue"
      :disabled="disabled"
      :clearable="clearable"
      :error="!!error"
      :placeholder="placeholder"
      :min-date="minDate"
      :max-date="maxDate"
      @update:modelValue="
        (value) => {
          $emit('update:modelValue', value);
        }
      "
      @open="$emit('open')"
      @close="$emit('close')"
      @input="$emit('input', $event)"
    >
    </p-date-picker>
  </p-form-element>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  model: {
    prop: 'modelValue',
    event: 'update:modelValue'
  },
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: null }) public readonly modelValue!: string | null;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: Boolean, required: false, default: true }) public readonly clearable!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly placeholder?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip?: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly minDate?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly maxDate?: string;

  @Prop({
    type: String as PropType<'small' | 'medium'>,
    default: 'medium',
    validator: (value: string) => ['small', 'medium'].includes(value)
  })
  public readonly size!: 'small' | 'medium';
}
</script>
