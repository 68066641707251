<template>
  <p-container gap-size="extra-large" v-if="campaign && campaignType && ($route.params.hash || menuItems.length > 0)">
    <p-custom-campaign-topbar v-if="!$route.params.hash" :campaign="campaign" />

    <p-page :top-spacing="!!$route.params.hash" send-analytics>
      <p-nav-tabs-campaign v-if="!$route.params.hash" :campaign="campaign.resource" :menu-items="menuItems" />

      <!-- Main area chart -->
      <p-section headline="Campaign statistics" :headline-size="headlineSize">
        <p-row align-items="center" v-device-desktop>
          <p-column>
            <p-tabs>
              <p-tabs-item
                v-for="tab in dateRanges"
                :key="tab.id"
                :selected.prop="selectedDateRange === tab.id"
                :text="tab.label"
                :disabled.prop="!reactive || waitingForChartData"
                size="medium"
                @select="selectedDateRange = String(tab.id)"
              />
            </p-tabs>
          </p-column>
          <p-column>
            <p-row justify-content="flex-end">
              <p-form-date-picker
                size="small"
                v-model="selectedFromDate"
                label="From"
                :disabled="!reactive || waitingForChartData"
              />
              <p-form-date-picker
                size="small"
                v-model="selectedToDate"
                label="To"
                :disabled="!reactive || waitingForChartData"
              />
            </p-row>
          </p-column>
        </p-row>

        <p-row align-items="center" v-device-mobile>
          <p-column size="extra-large">
            <p-tabs>
              <p-tabs-item
                v-for="tab in dateRanges"
                :key="tab.id"
                :selected.prop="selectedDateRange === tab.id"
                :text="tab.label"
                :disabled.prop="!reactive || waitingForChartData"
                size="small"
                @select="selectedDateRange = String(tab.id)"
              />
            </p-tabs>
          </p-column>
          <p-column size="extra-large">
            <p-row justify-content="space-between">
              <p-form-date-picker
                size="medium"
                v-model="selectedFromDate"
                label="From"
                :disabled="!reactive || waitingForChartData"
              />
              <p-form-date-picker
                size="medium"
                v-model="selectedToDate"
                label="To"
                :disabled="!reactive || waitingForChartData"
              />
            </p-row>
          </p-column>
        </p-row>

        <p-row>
          <p-column justify-content="center">
            <p-chart-area :data-sets="chartData" :loading="waitingForChartData" />
          </p-column>
        </p-row>

        <p-row>
          <p-column>
            <p-tabs>
              <p-tabs-item
                v-for="tab in chartDisplayTabs"
                :key="tab.id"
                :selected.prop="selectedChartDisplay === tab.id"
                :text="tab.label"
                :disabled.prop="!reactive || waitingForChartData"
                size="medium"
                @select="selectedChartDisplay = String(tab.id)"
              />
            </p-tabs>
          </p-column>

          <p-column v-if="!$route.params.hash">
            <p-row gap-size="small" justify-content="flex-end">
              <p-button size="small" color-type="tertiary" @click="showEmailNotification = true"
                >Email notification</p-button
              >

              <p-link
                :href="`/campaign/${campaignType.alias}/${campaign.resource.id}/activity/statistics/${campaign.resource.hash}`"
                target="_blank"
              >
                <p-button size="small" color-type="tertiary">Public URL</p-button>
              </p-link>

              <p-button size="small" color-type="tertiary" @click="showExportModal = true">Export statistics</p-button>
            </p-row>
          </p-column>
        </p-row>
      </p-section>

      <!-- Realtime stats -->
      <p-row align-items="stretch" v-device-desktop v-if="enableStatistic">
        <p-column>
          <p-custom-stat-box-inverted headline="Real time statistics">
            <template #left>
              <div class="devices">
                <div class="devices__item">
                  <div class="devices__item-color devices__item-color--mobile"></div>
                  <div class="devices__item-text">
                    <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                    <template v-else>
                      <span>{{ realtimeText.mobile }}</span>
                      <span>Mobile</span>
                      <span>{{ realtimePercentage.mobile }}%</span>
                    </template>
                  </div>
                </div>

                <div class="devices__item">
                  <div class="devices__item-color devices__item-color--desktop"></div>
                  <div class="devices__item-text">
                    <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                    <template v-else>
                      <span>{{ realtimeText.desktop }}</span>
                      <span>Desktop</span>
                      <span>{{ realtimePercentage.desktop }}%</span>
                    </template>
                  </div>
                </div>

                <div class="devices__item">
                  <div class="devices__item-color devices__item-color--tablet"></div>
                  <div class="devices__item-text">
                    <p-skeleton-loader v-if="waitingForRealtimeData" type="text" />
                    <template v-else>
                      <span>{{ realtimeText.tablet }}</span>
                      <span>Tablet</span>
                      <span>{{ realtimePercentage.tablet }}%</span>
                    </template>
                  </div>
                </div>
              </div>

              <div class="info">
                <div class="info__help">
                  The numbers above indicate how many players are actively interacting with your campaign right now.
                </div>
              </div>
            </template>

            <template #right>
              <div class="stat-box-chart">
                <p-chart-doughnut
                  :loading="waitingForRealtimeData"
                  :data-sets="realtimeChartData"
                  :labels="realtimeLabels"
                />
              </div>
            </template>
          </p-custom-stat-box-inverted>
        </p-column>

        <p-column>
          <p-custom-stat-box-inverted headline="Device distribution">
            <template #left>
              <div class="devices">
                <div class="devices__item">
                  <div class="devices__item-color devices__item-color--mobile"></div>
                  <div class="devices__item-text">
                    <p-skeleton-loader v-if="waitingForChartData" type="text" />
                    <template v-else>
                      <span>{{ distributionText.mobile }}</span>
                      <span>Mobile</span>
                      <span>{{ distributionPercentage.mobile }}%</span>
                    </template>
                  </div>
                </div>

                <div class="devices__item">
                  <div class="devices__item-color devices__item-color--desktop"></div>
                  <div class="devices__item-text">
                    <p-skeleton-loader v-if="waitingForChartData" type="text" />
                    <template v-else>
                      <span>{{ distributionText.desktop }}</span>
                      <span>Desktop</span>
                      <span>{{ distributionPercentage.desktop }}%</span>
                    </template>
                  </div>
                </div>

                <div class="devices__item">
                  <div class="devices__item-color devices__item-color--tablet"></div>
                  <div class="devices__item-text">
                    <p-skeleton-loader v-if="waitingForChartData" type="text" />
                    <template v-else>
                      <span>{{ distributionText.tablet }}</span>
                      <span>Tablet</span>
                      <span>{{ distributionPercentage.tablet }}%</span>
                    </template>
                  </div>
                </div>
              </div>

              <div class="info">
                <div class="info__help">
                  The numbers above indicate the distribution of devices across all players of the campaign.
                </div>
              </div>
            </template>

            <template #right>
              <div class="stat-box-chart">
                <p-chart-doughnut
                  :loading="waitingForChartData"
                  :data-sets="distributionChartData"
                  :labels="distributionLabels"
                />
              </div>
            </template>
          </p-custom-stat-box-inverted>
        </p-column>
      </p-row>

      <p-row>
        <p-column v-if="enableStatistic">
          <p-custom-stat-box
            headline="Total sessions"
            icon="user"
            :loading="waitingForChartData"
            :value="totalSessions"
          />
        </p-column>

        <p-column>
          <p-custom-stat-box
            headline="Registrations"
            icon="users"
            :loading="waitingForChartData"
            :value="totalRegistrations"
          />
        </p-column>

        <p-column>
          <p-tooltip
            v-if="
              campaign.resource.limit_field &&
              uniqueRegistrations &&
              (uniqueRegistrations / totalRegistrations) * 100 < 1
            "
            text="Registration with unique identifiers is not possible if email is not employed as a constraint"
            position="bottom"
            display="block"
          >
            <p-custom-stat-box headline="Unique registrations" icon="user-check" value="N/A" />
          </p-tooltip>

          <p-tooltip
            v-else-if="
              ['7_day_after_registration', '14_day_after_registration', '30_day_after_registration'].includes(
                campaign.resource.retention
              )
            "
            text="Unique registrations are unavailable due to the data retention period."
            position="bottom"
            display="block"
          >
            <p-custom-stat-box headline="Unique registrations" icon="user-check" value="N/A" />
          </p-tooltip>

          <p-custom-stat-box
            v-else
            headline="Unique registrations"
            icon="user-check"
            :loading="waitingForUniqueRegistrations"
            :value="uniqueRegistrations"
          />
        </p-column>

        <p-column>
          <p-custom-stat-box
            headline="Conversion"
            icon="bar-chart-2"
            :loading="waitingForChartData"
            :value="conversion"
          />
        </p-column>
      </p-row>

      <layout-element v-if="enableStatistic" :element="googleMap" />

      <p-section v-if="enableStatistic" headline="External links (Top 10)">
        <p-table-client
          :header="[
            {
              id: 'url',
              label: 'URL'
            },
            {
              id: 'total',
              label: 'Total',
              width: '120px'
            }
          ]"
          :body="externalLinksBody"
          :skeleton-loader="waitingForExternalLinks"
          :skeleton-loader-rows="10"
        />
      </p-section>

      <p-row>
        <p-column v-if="enableStatistic">
          <p-tooltip
            v-if="!hasTimeSpendData"
            text="Time spent data is not available for campaigns with less than two flowpages"
            position="bottom"
            display="block"
            style="min-width: 100%"
          >
            <p-custom-stat-box
              headline="Time spent"
              icon="clock"
              :loading="waitingForSummaryStats"
              value="00.00"
              :disabled="true"
              label="Per visit"
            />
          </p-tooltip>

          <p-custom-stat-box
            v-else
            headline="Time spent"
            icon="clock"
            :loading="waitingForSummaryStats"
            :value="timeSpentAverage"
            label="Per visit"
          />
        </p-column>

        <p-column v-if="enableStatistic">
          <p-tooltip
            v-if="!hasTimeSpendData"
            text="Time spent data is not available for campaigns with less than two flowpages"
            position="bottom"
            display="block"
            style="min-width: 100%"
          >
            <p-custom-stat-box
              headline="Time spent"
              icon="clock"
              :loading="waitingForSummaryStats"
              value="0"
              :disabled="true"
              label="Total (hours)"
            />
          </p-tooltip>

          <p-custom-stat-box
            v-else
            headline="Time spent"
            icon="clock"
            :loading="waitingForSummaryStats"
            :value="timeSpentHours"
            label="Total (hours)"
          />
        </p-column>

        <p-column>
          <p-custom-stat-box
            v-if="sentToEspTotal === 0 || !hasUpdateIntegration"
            headline="Sent to ESP"
            icon="send"
            :loading="waitingForSentToEsp"
            :value="sentToEspTotal"
            label="Non-Unique registrations"
          />

          <p-slide-show v-else>
            <p-custom-stat-box
              headline="Sent to ESP"
              icon="send"
              :loading="waitingForSentToEsp"
              :value="sentToEspTotal"
              label="Non-Unique registrations"
            />
            <p-custom-stat-box
              v-if="sentToEspTotalCreated > 0"
              headline="Created in ESP"
              icon="send"
              :loading="waitingForSentToEsp"
              :value="sentToEspTotalCreated"
              label="Unique registrations"
            />
            <p-custom-stat-box
              v-if="sentToEspTotalUpdated > 0"
              headline="Updated in ESP"
              icon="send"
              :loading="waitingForSentToEsp"
              :value="sentToEspTotalUpdated"
              label="Non-Unique registrations"
            />
          </p-slide-show>
        </p-column>

        <p-column>
          <p-custom-stat-box
            headline="Tip a friend"
            icon="mail"
            :loading="waitingForSummaryStats"
            :value="summaryStatsData?.tip_a_friend"
            label="Unique referrals"
          />
        </p-column>
      </p-row>

      <p-section v-if="enableStatistic" headline="Funnel statistic">
        <div
          class="funnel"
          v-if="waitingForSections || waitingForFlowPageSessions || waitingForFlowPages || waitingForSentToEsp"
        >
          <div class="funnel__section">
            <div class="funnel__section-inner">
              <div class="funnel__section-card">
                <p-skeleton-loader type="image" size="extra-large" />
              </div>
              <div class="funnel__connector funnel__connector--bottom"></div>
            </div>
          </div>

          <div class="funnel__section">
            <div class="funnel__section-inner">
              <div class="funnel__section-card">
                <p-skeleton-loader type="image" size="extra-large" />
              </div>
              <div class="funnel__connector funnel__connector--bottom"></div>
            </div>

            <div class="funnel__flow-page">
              <div class="funnel__flow-page-inner">
                <div class="funnel__flow-page-card">
                  <p-skeleton-loader type="image" size="extra-large" />
                </div>
                <div class="funnel__connector funnel__connector--left"></div>
              </div>
            </div>

            <div class="funnel__flow-page">
              <div class="funnel__flow-page-inner">
                <div class="funnel__flow-page-card">
                  <p-skeleton-loader type="image" size="extra-large" />
                </div>
                <div class="funnel__connector funnel__connector--left"></div>
              </div>
            </div>

            <div class="funnel__flow-page">
              <div class="funnel__flow-page-inner">
                <div class="funnel__flow-page-card">
                  <p-skeleton-loader type="image" size="extra-large" />
                </div>

                <div class="funnel__connector funnel__connector--left"></div>
                <div class="funnel__connector funnel__connector--bottom"></div>
              </div>

              <div class="funnel__decorator">
                <div class="funnel__decorator-inner">
                  <div class="funnel__decorator-card">
                    <p-skeleton-loader type="image" size="extra-large" />
                  </div>
                  <div class="funnel__connector funnel__connector--bottom"></div>
                </div>
              </div>

              <div class="funnel__decorator">
                <div class="funnel__decorator-inner">
                  <div class="funnel__decorator-card">
                    <p-skeleton-loader type="image" size="extra-large" />
                  </div>
                </div>
              </div>
            </div>

            <div class="funnel__flow-page">
              <div class="funnel__flow-page-inner">
                <div class="funnel__flow-page-card">
                  <p-skeleton-loader type="image" size="extra-large" />
                </div>
                <div class="funnel__connector funnel__connector--left"></div>
              </div>
            </div>

            <div class="funnel__flow-page">
              <div class="funnel__flow-page-inner">
                <div class="funnel__flow-page-card">
                  <p-skeleton-loader type="image" size="extra-large" />
                </div>
                <div class="funnel__connector funnel__connector--left"></div>
              </div>
            </div>
          </div>

          <div class="funnel__section">
            <div class="funnel__section-inner">
              <div class="funnel__section-card">
                <p-skeleton-loader type="image" size="extra-large" />
              </div>
            </div>
          </div>
        </div>

        <div class="funnel" v-else-if="campaignType.alias === 'popup' || campaignType.alias === 'popupv2'">
          <div class="funnel__section">
            <template>
              <div class="funnel__flow-page" v-for="flowPage in flowPages" :key="flowPage.resource.id">
                <div class="funnel__flow-page-inner">
                  <div class="funnel__flow-page-card">
                    <p-card
                      :headline="flowPage.resource.name"
                      :description="`${
                        flowPageSessionsData ? flowPageSessionsData[flowPage.resource.id] ?? 'N/A' : 'N/A'
                      } sessions (${flowPageSessionMap[flowPage.resource.id] ?? 'N/A'})`"
                    />
                  </div>
                  <div class="funnel__connector funnel__connector--left"></div>
                  <div
                    v-if="flowPage.uniqueAddons.includes('registration')"
                    class="funnel__connector funnel__connector--bottom"
                  ></div>
                </div>

                <template v-if="flowPage.uniqueAddons.includes('registration')">
                  <div class="funnel__decorator">
                    <div class="funnel__decorator-inner">
                      <div class="funnel__decorator-card">
                        <p-card
                          headline="Form"
                          :description="`${totalRegistrations} registrations (not unique) - conv. ${conversion}`"
                        />
                      </div>
                      <div class="funnel__connector funnel__connector--bottom" v-if="espIntegrations.length > 0"></div>
                    </div>
                  </div>

                  <div class="funnel__decorator" v-for="(esp, espIndex) in espIntegrations" :key="esp.resource.id">
                    <div class="funnel__decorator-inner">
                      <div class="funnel__decorator-card">
                        <p-card
                          :headline="esp.resource.name"
                          :description="`${
                            sentToEspData?.mapped ? sentToEspData.mapped[esp.resource.id] ?? 'N/A' : 'N/A'
                          } registrations sent to ${esp.resource.name} (not unique)`"
                        />
                      </div>

                      <div
                        class="funnel__connector funnel__connector--bottom"
                        v-if="espIntegrations.length !== espIndex + 1"
                      ></div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>

        <div class="funnel" v-else>
          <div class="funnel__section" v-for="(section, sectionIndex) in sections" :key="section.resource.id">
            <div class="funnel__section-inner">
              <div class="funnel__section-card">
                <p-card
                  :headline="section.resource.name"
                  :description="
                    !section.resource.show_on_desktop && section.resource.show_on_mobile
                      ? 'Only on mobile'
                      : section.resource.show_on_desktop && !section.resource.show_on_mobile
                      ? 'Only on desktop'
                      : 'All devices'
                  "
                />
              </div>
              <div
                v-if="sections.length !== sectionIndex + 1"
                class="funnel__connector funnel__connector--bottom"
              ></div>
            </div>

            <template v-if="section.uniqueAddons.includes('gameflow')">
              <div class="funnel__flow-page" v-for="(flowPage, flowPageIndex) in flowPages" :key="flowPage.resource.id">
                <div class="funnel__flow-page-inner">
                  <div class="funnel__flow-page-card">
                    <p-card
                      :headline="flowPage.resource.name"
                      :description="
                        campaign.resource.device_type === 'Ads' && flowPageIndex === 0
                          ? 'N/A'
                          : `${
                              flowPageSessionsData ? flowPageSessionsData[flowPage.resource.id] ?? 'N/A' : 'N/A'
                            } sessions (${flowPageSessionMap[flowPage.resource.id] ?? 'N/A'})`
                      "
                    />
                  </div>
                  <div class="funnel__connector funnel__connector--left"></div>
                  <div
                    v-if="flowPage.uniqueAddons.includes('registration')"
                    class="funnel__connector funnel__connector--bottom"
                  ></div>
                </div>

                <template v-if="flowPage.uniqueAddons.includes('registration')">
                  <div class="funnel__decorator">
                    <div class="funnel__decorator-inner">
                      <div class="funnel__decorator-card">
                        <p-card
                          headline="Form"
                          :description="`${totalRegistrations} registrations (not unique) - conv. ${conversion}`"
                        />
                      </div>
                      <div class="funnel__connector funnel__connector--bottom" v-if="espIntegrations.length > 0"></div>
                    </div>
                  </div>

                  <div class="funnel__decorator" v-for="(esp, espIndex) in espIntegrations" :key="esp.resource.id">
                    <div class="funnel__decorator-inner">
                      <div class="funnel__decorator-card">
                        <p-card
                          :headline="esp.resource.name"
                          :description="`${
                            sentToEspData?.mapped ? sentToEspData.mapped[esp.resource.id] ?? 'N/A' : 'N/A'
                          } registrations sent to ${esp.resource.name} (not unique)`"
                        />
                      </div>

                      <div
                        class="funnel__connector funnel__connector--bottom"
                        v-if="espIntegrations.length !== espIndex + 1"
                      ></div>
                    </div>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </p-section>

      <p-row v-if="enableStatistic" align-items="stretch" items-display="auto">
        <p-section headline="UTM (Top 10)">
          <p-tabs slot="toolbar">
            <p-tabs-item
              :selected.prop="selectedUtmTab === 'source'"
              text="UTM Source"
              :disabled.prop="waitingForUtm"
              @select="selectedUtmTab = 'source'"
            />

            <p-tabs-item
              :selected.prop="selectedUtmTab === 'medium'"
              text="UTM Medium"
              :disabled.prop="waitingForUtm"
              @select="selectedUtmTab = 'medium'"
            />

            <p-tabs-item
              :selected.prop="selectedUtmTab === 'campaign'"
              text="UTM Campaign"
              :disabled.prop="waitingForUtm"
              @select="selectedUtmTab = 'campaign'"
            />
          </p-tabs>

          <p-table-client
            :header="[
              {
                id: 'value',
                label: 'Value'
              },
              {
                id: 'sessions',
                label: 'Sessions',
                width: '120px'
              }
            ]"
            :body="utmData"
            :skeleton-loader="waitingForUtm"
            :skeleton-loader-rows="10"
          />
        </p-section>
        <p-section headline="Referrer statistics (Top 10)">
          <p-table-client
            :header="[
              {
                id: 'url',
                label: 'Referrer URL'
              },
              {
                id: 'sessions',
                label: 'Sessions',
                width: '120px'
              }
            ]"
            :body="referrerData"
            :skeleton-loader="waitingForReferrer"
            :skeleton-loader-rows="10"
          />
        </p-section>
      </p-row>

      <p-section v-if="enableStatistic" headline="Daily sessions / registrations">
        <p-table-client
          :header="[
            {
              id: 'date',
              label: 'Date'
            },
            {
              id: 'desktop',
              label: 'Desktop'
            },
            {
              id: 'tablet',
              label: 'Tablet'
            },
            {
              id: 'mobile',
              label: 'Mobile'
            },
            {
              id: 'total',
              label: 'Total'
            }
          ]"
          :body="dailyStatsBody"
          :skeleton-loader="waitingForChartData"
          :skeleton-loader-rows="5"
          :pagination="true"
        />
      </p-section>
    </p-page>

    <layout-element-popup
      v-if="showEmailNotification"
      :url="`/campaign/${campaignType.alias}/${campaign.resource.id}/activity/statistics/email-notification`"
      headline="Email notification"
      @closePopup="showEmailNotification = false"
      @actionClosePopup="onActionClosePopup()"
    />

    <layout-element-popup
      v-if="showExportModal"
      :url="`/campaign/${campaignType.alias}/${campaign.resource.id}/activity/export-xls-statistics?from=${selectedFromDate}&to=${selectedToDate}`"
      @closePopup="showExportModal = false"
      @actionClosePopup="onActionClosePopup()"
    />
  </p-container>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { TabItem } from '@/components/ui/form/types';
import format from 'date-fns/format';
import { ChartAreaDataSet, ChartDoughnutDataSet } from '@/components/ui/chart/types';
import { TableBody } from '@/components/ui/Table.vue';
import { IElementGoogleMap } from '@/interfaces/element';
import LayoutElementPopup from '@/components/LayoutElementPopup.vue';
import {
  CampaignCountryStatsData,
  CampaignExternalLinksData,
  CampaignFlowPageSessionsData,
  CampaignModel,
  CampaignRealtimeData,
  CampaignReferrerData,
  CampaignSentToEspData,
  CampaignSummaryData,
  CampaignUtmData
} from '@/models/campaign';
import subMonths from 'date-fns/subMonths';
import { CanceledError } from 'axios';
import { CampaignPageModel } from '@/models/campaign/page';
import { CampaignIntegrationModel } from '@/models/campaign/integration';
import { CampaignTypeResource } from '@/types/api/campaign';
import { AppRequest } from '@/app_request';
import { IMenuItem } from '@/interfaces/menu';
import { getDevice } from '@/utility';

interface DistributionData {
  desktop: number;
  tablet: number;
  mobile: number;
}

interface DailyStatsDataRow {
  date: number;

  desktop: {
    sessions: number;
    registrations: number;
  };

  tablet: {
    sessions: number;
    registrations: number;
  };

  mobile: {
    sessions: number;
    registrations: number;
  };
}

@Component({
  components: { LayoutElementPopup }
})
export default class extends Vue {
  public dateRanges: TabItem[] = [
    {
      id: '1-month',
      label: '1 month'
    },
    {
      id: '6-months',
      label: '6 months'
    },
    {
      id: '12-months',
      label: '12 months'
    },
    {
      id: 'campaign-period',
      label: 'Campaign period'
    }
  ];

  public chartDisplayTabs: TabItem[] = [
    {
      id: 'sessions',
      label: 'Sessions'
    },
    {
      id: 'registrations',
      label: 'Registrations'
    }
  ];

  public selectedDateRange = 'campaign-period';
  public selectedChartDisplay = 'sessions';
  public selectedFromDate = '';
  public selectedToDate = '';

  public waitingForRealtimeData = true;
  public realtimeApiData: CampaignRealtimeData | null = null;

  public waitingForCampaign = true;
  public campaign: CampaignModel | null = null;
  public campaignType: CampaignTypeResource | null = null;

  public waitingForUniqueRegistrations = true;
  public uniqueRegistrations = 0;

  public showEmailNotification = false;
  public showExportModal = false;

  public topBarReady = false;

  public waitingForChartData = true;
  public chartSessionData: ChartAreaDataSet[] = [];
  public chartRegistrationData: ChartAreaDataSet[] = [];
  public chartMobileColor = '';
  public chartTabletColor = '';
  public chartDesktopColor = '';

  public waitingForSummaryStats = true;
  public summaryStatsData: CampaignSummaryData | null = null;

  public waitingForCountryStats = true;
  public countryStatsData: CampaignCountryStatsData[] = [];

  public waitingForReferrer = true;
  public referrerData: CampaignReferrerData[] = [];

  public waitingForExternalLinks = true;
  public externalLinksData: CampaignExternalLinksData[] = [];

  public selectedUtmTab: 'source' | 'medium' | 'campaign' = 'source';
  public waitingForUtm = true;
  public utmData: CampaignUtmData[] = [];

  public waitingForFlowPages = true;
  public flowPages: CampaignPageModel[] = [];

  public waitingForSections = true;
  public sections: CampaignPageModel[] = [];

  public waitingForIntegrations = true;
  public integrations: CampaignIntegrationModel[] = [];

  public waitingForSentToEsp = true;
  public sentToEspData: CampaignSentToEspData | null = null;
  public sentToEspTotal = 0;
  public sentToEspTotalCreated = 0;
  public sentToEspTotalUpdated = 0;

  public waitingForFlowPageSessions = true;
  public flowPageSessionsData: CampaignFlowPageSessionsData | null = null;

  public menuItems: IMenuItem[] = [];

  public reactive = false;
  public enableStatistic = false;

  public async mounted() {
    const computedDocumentStyle = window.getComputedStyle(document.documentElement);
    this.chartMobileColor = computedDocumentStyle.getPropertyValue('--color-brand-primary');
    this.chartTabletColor = computedDocumentStyle.getPropertyValue('--color-brand-tertiary');
    this.chartDesktopColor = computedDocumentStyle.getPropertyValue('--color-brand-secondary');

    const campaign = await this.loadCampaign();

    if (!this.$route.params.hash) {
      this.menuItems = (await AppRequest.get<IMenuItem[]>(`/api/v1/campaign/${campaign.resource.uuid}/nav`)).data;
    }

    if (campaign.resource.active) {
      this.selectedFromDate = format(new Date(campaign.resource.active_from), 'yyyy-MM-dd');
      this.selectedToDate = format(new Date(campaign.resource.active_to), 'yyyy-MM-dd');
    } else {
      this.selectedDateRange = '1-month';
      this.selectedFromDate = format(subMonths(new Date(), 1), 'yyyy-MM-dd');
      this.selectedToDate = format(new Date(), 'yyyy-MM-dd');
    }

    await this.loadDateSensitiveData(true);

    // Load the rest of the data one at a time. If one fails, silence the error and continue on.
    // Better to show something, rather than nothing at all.
    try {
      await this.loadRealtimeData();
    } catch (e) {
      /* empty */
    }

    try {
      await this.loadUtm();
    } catch (e) {
      /* empty */
    }

    try {
      await this.loadUniqueRegistrations();
    } catch (e) {
      /* empty */
    }

    try {
      await this.loadExternalLinks();
    } catch (e) {
      /* empty */
    }

    try {
      await this.loadReferrer();
    } catch (e) {
      /* empty */
    }

    this.flowPages = await campaign.flowPages.list();
    this.waitingForFlowPages = false;

    this.sections = await campaign.sections.list();
    this.waitingForSections = false;

    this.integrations = await campaign.integrations.list();
    this.waitingForIntegrations = false;

    try {
      await this.loadSummary();
    } catch (e) {
      /* empty */
    }
  }

  @Watch('selectedDateRange')
  public onDateRangeChange() {
    this.loadDateSensitiveData();
  }

  @Watch('selectedFromDate')
  public onDateFromChange() {
    this.loadDateSensitiveData();
  }

  @Watch('selectedToDate')
  public onDateToChange() {
    this.loadDateSensitiveData();
  }

  @Watch('selectedUtmTab')
  public onUtmChange() {
    this.loadUtm();
  }

  public onActionClosePopup() {
    setTimeout(() => {
      this.showEmailNotification = this.showExportModal = false;
    }, 1000);
  }

  public get hasUpdateIntegration() {
    if (this.integrations.length === 0) return false;
    // if we have an integration with update functionality we display a slider
    return this.integrations.some((integration) => integration.resource.esp?.capabilities.indexOf('update') !== -1);
  }

  public get hasTimeSpendData() {
    if (this.waitingForFlowPages) return false;
    // need min 2 flow pages to have time spend data
    return this.flowPages.length >= 2;
  }

  public async loadDateSensitiveData(force = false) {
    if (!this.reactive && !force) {
      return;
    }

    this.reactive = false;

    await Promise.allSettled([
      this.loadChartData(),
      this.loadCountryStats(),
      this.loadFlowPageSessions(),
      this.loadSentToEsp(),
      this.loadUniqueRegistrations(),
      this.loadSummary()
    ]);

    this.reactive = true;
  }

  private async loadSummary() {
    if (!this.campaign) {
      return;
    }

    this.waitingForSummaryStats = true;
    this.summaryStatsData = await this.campaign.statistics.getSummary(this.selectedFromDate, this.selectedToDate);
    this.waitingForSummaryStats = false;
  }

  private async loadUniqueRegistrations() {
    if (!this.campaign) {
      return;
    }

    this.waitingForUniqueRegistrations = true;
    this.uniqueRegistrations = await this.campaign.statistics.getUniqueRegistrations(
      this.selectedFromDate,
      this.selectedToDate
    );
    this.waitingForUniqueRegistrations = false;
  }

  private async loadRealtimeData() {
    if (!this.campaign || !this.enableStatistic) {
      return;
    }

    this.waitingForRealtimeData = true;
    this.realtimeApiData = await this.campaign.statistics.getRealtime();
    this.waitingForRealtimeData = false;
  }

  private async loadExternalLinks() {
    if (!this.campaign || !this.enableStatistic) {
      return;
    }

    this.waitingForExternalLinks = true;
    this.externalLinksData = await this.campaign.statistics.getExternalLinks();
    this.waitingForExternalLinks = false;
  }

  private async loadUtm() {
    if (!this.campaign || !this.enableStatistic) {
      return;
    }

    this.waitingForUtm = true;
    this.utmData = await this.campaign.statistics.getUtm(this.selectedUtmTab);
    this.waitingForUtm = false;
  }

  private async loadSentToEsp() {
    if (!this.campaign) {
      return;
    }

    this.waitingForSentToEsp = true;
    this.sentToEspData = await this.campaign.statistics.getSentToEsp();

    this.sentToEspTotalCreated = this.sentToEspData.created;
    this.sentToEspTotalUpdated = this.sentToEspData.updated;
    this.sentToEspTotal = this.sentToEspData.total;
    this.waitingForSentToEsp = false;
  }

  private async loadFlowPageSessions() {
    if (!this.campaign || !this.enableStatistic) {
      return;
    }

    this.waitingForFlowPageSessions = true;
    this.flowPageSessionsData = await this.campaign.statistics.getFlowPageSessions(
      this.selectedFromDate,
      this.selectedToDate
    );
    this.waitingForFlowPageSessions = false;
  }

  private async loadCountryStats() {
    if (!this.campaign || !this.enableStatistic) {
      return;
    }

    this.waitingForCountryStats = true;
    this.countryStatsData = await this.campaign.statistics.getSessionsMap(this.selectedFromDate, this.selectedToDate);
    this.waitingForCountryStats = false;
  }

  private async loadReferrer() {
    if (!this.campaign || !this.enableStatistic) {
      return;
    }

    this.waitingForReferrer = true;
    this.referrerData = await this.campaign.statistics.getReferrer();
    this.waitingForReferrer = false;
  }

  public async loadCampaign() {
    this.waitingForCampaign = true;

    this.campaign = this.$route.params.hash
      ? await CampaignModel.fromHash(String(this.$route.params.hash))
      : await CampaignModel.fromId(Number(this.$route.params.campaignId));

    if (this.campaign.resource.enable_statistics) {
      this.enableStatistic = true;
    }

    this.campaignType = await this.campaign.getType();
    this.chartDisplayTabs[1].disabled = this.campaign.resource.state === 'expired';

    if (!this.enableStatistic) {
      this.selectedChartDisplay = 'registrations';
      this.chartDisplayTabs = [
        {
          id: 'registrations',
          label: 'Registrations'
        }
      ];
    }

    this.waitingForCampaign = false;

    return this.campaign;
  }

  private async loadChartData() {
    this.waitingForChartData = true;

    try {
      await Promise.allSettled([this.loadChartSessionData(), this.loadChartRegistrationData()]);
      this.waitingForChartData = false;
    } catch (e) {
      // Ignore cancelled errors, since we manually abort the requests on subsequent calls
      if (!(e instanceof CanceledError)) {
        throw e;
      }
    }
  }

  private async loadChartSessionData() {
    if (!this.campaign || !this.enableStatistic) {
      return;
    }

    const response = await this.campaign.statistics.getSessions(this.selectedFromDate, this.selectedToDate);

    this.chartSessionData = [
      {
        text: 'Desktop',
        color: this.chartDesktopColor,
        data: response.desktop
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      },
      {
        text: 'Tablet',
        color: this.chartTabletColor,
        data: response.tablet
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      },
      {
        text: 'Mobile',
        color: this.chartMobileColor,
        data: response.mobile
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      }
    ];
  }

  private async loadChartRegistrationData() {
    if (!this.campaign) {
      return;
    }

    const response = await this.campaign.statistics.getRegistrations(this.selectedFromDate, this.selectedToDate);

    this.chartRegistrationData = [
      {
        text: 'Desktop',
        color: this.chartDesktopColor,
        data: response.desktop
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      },
      {
        text: 'Tablet',
        color: this.chartTabletColor,
        data: response.tablet
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      },
      {
        text: 'Mobile',
        color: this.chartMobileColor,
        data: response.mobile
          .map((dataPoint) => {
            return {
              date: dataPoint.date * 1000,
              value: dataPoint.value
            };
          })
          .sort(function (a, b) {
            if (a.date < b.date) return -1;
            if (a.date > b.date) return 1;
            return 0;
          })
      }
    ];
  }

  public get chartData(): ChartAreaDataSet[] {
    if (this.selectedChartDisplay === 'registrations') {
      return this.chartRegistrationData;
    }

    return this.chartSessionData;
  }

  // --- Realtime
  public get realtimeText() {
    if (!this.realtimeApiData) {
      return {
        desktop: '0',
        tablet: '0',
        mobile: '0'
      };
    }

    return {
      desktop: `${this.realtimeApiData.desktop}`,
      tablet: `${this.realtimeApiData.tablet}`,
      mobile: `${this.realtimeApiData.mobile}`
    };
  }

  public get realtimePercentage() {
    if (!this.realtimeApiData) {
      return {
        desktop: '0',
        tablet: '0',
        mobile: '0'
      };
    }

    const total = this.realtimeApiData.desktop + this.realtimeApiData.tablet + this.realtimeApiData.mobile;

    const desktop = this.realtimeApiData.desktop ? Math.floor((this.realtimeApiData.desktop / total) * 100) : 0;
    const tablet = this.realtimeApiData.tablet ? Math.floor((this.realtimeApiData.tablet / total) * 100) : 0;
    const mobile = this.realtimeApiData.mobile ? Math.floor((this.realtimeApiData.mobile / total) * 100) : 0;
    const left = total > 0 ? 100 - desktop - tablet - mobile : 0;

    return {
      desktop: `${left > 0 && mobile === 0 ? desktop + left : desktop}`,
      tablet: `${tablet}`,
      mobile: `${left > 0 && mobile > 0 ? mobile + left : mobile}`
    };
  }

  public get realtimeLabels(): string[] {
    if (
      this.realtimeApiData &&
      this.realtimeApiData.desktop + this.realtimeApiData.tablet + this.realtimeApiData.mobile === 0
    ) {
      return ['No data'];
    }

    return ['Desktop', 'Tablet', 'Mobile'];
  }

  public get realtimeChartData(): ChartDoughnutDataSet[] {
    if (!this.realtimeApiData) {
      return [];
    }

    if (this.realtimeApiData.desktop + this.realtimeApiData.tablet + this.realtimeApiData.mobile === 0) {
      return [
        {
          colors: ['#fff'],
          data: [100]
        }
      ];
    }

    return [
      {
        colors: [this.chartDesktopColor, this.chartTabletColor, this.chartMobileColor],
        data: [this.realtimeApiData.desktop, this.realtimeApiData.tablet, this.realtimeApiData.mobile]
      }
    ];
  }

  // --- Distribution
  public get distributionText() {
    if (!this.distributionData) {
      return {
        desktop: '0',
        tablet: '0',
        mobile: '0'
      };
    }

    return {
      desktop: `${this.distributionData.desktop}`,
      tablet: `${this.distributionData.tablet}`,
      mobile: `${this.distributionData.mobile}`
    };
  }

  public get distributionPercentage() {
    if (!this.distributionData) {
      return {
        desktop: '0',
        tablet: '0',
        mobile: '0'
      };
    }

    const total = this.distributionData.desktop + this.distributionData.tablet + this.distributionData.mobile;

    const desktop = this.distributionData.desktop ? Math.floor((this.distributionData.desktop / total) * 100) : 0;
    const tablet = this.distributionData.tablet ? Math.floor((this.distributionData.tablet / total) * 100) : 0;
    const mobile = this.distributionData.mobile ? Math.floor((this.distributionData.mobile / total) * 100) : 0;
    const left = total > 0 ? 100 - desktop - tablet - mobile : 0;

    return {
      desktop: `${left > 0 && mobile === 0 ? desktop + left : desktop}`,
      tablet: `${tablet}`,
      mobile: `${left > 0 && mobile > 0 ? mobile + left : mobile}`
    };
  }

  public get distributionLabels(): string[] {
    if (
      this.distributionData &&
      this.distributionData.desktop + this.distributionData.tablet + this.distributionData.mobile === 0
    ) {
      return ['No data'];
    }

    return ['Desktop', 'Tablet', 'Mobile'];
  }

  public get distributionChartData(): ChartDoughnutDataSet[] {
    if (!this.distributionData || this.waitingForChartData) {
      return [];
    }

    if (this.distributionData.desktop + this.distributionData.tablet + this.distributionData.mobile === 0) {
      return [
        {
          colors: ['#fff'],
          data: [100]
        }
      ];
    }

    return [
      {
        colors: [this.chartDesktopColor, this.chartTabletColor, this.chartMobileColor],
        data: [this.distributionData.desktop, this.distributionData.tablet, this.distributionData.mobile]
      }
    ];
  }

  public get distributionData(): DistributionData {
    const desktop =
      this.chartSessionData
        .find((item) => item.text === 'Desktop')
        ?.data.reduce((previousValue, item) => {
          return previousValue + item.value;
        }, 0) ?? 0;

    const tablet =
      this.chartSessionData
        .find((item) => item.text === 'Tablet')
        ?.data.reduce((previousValue, item) => {
          return previousValue + item.value;
        }, 0) ?? 0;

    const mobile =
      this.chartSessionData
        .find((item) => item.text === 'Mobile')
        ?.data.reduce((previousValue, item) => {
          return previousValue + item.value;
        }, 0) ?? 0;

    return {
      desktop,
      tablet,
      mobile
    };
  }

  // -- Total sessions/registrations
  public get totalSessions(): number {
    return this.chartSessionData.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        currentValue.data.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.value;
        }, 0)
      );
    }, 0);
  }

  public get totalRegistrations(): number {
    if (this.campaign?.resource.state === 'expired') {
      return this.campaign.resource.registrations ?? 0;
    }

    return this.chartRegistrationData.reduce((previousValue, currentValue) => {
      return (
        previousValue +
        currentValue.data.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.value;
        }, 0)
      );
    }, 0);
  }

  public get conversion(): string {
    if (this.totalSessions === 0 || this.totalRegistrations === 0) {
      return '0%';
    }

    return `${((this.totalRegistrations / this.totalSessions) * 100).toFixed(1).replace('.', ',')}%`;
  }

  // ---- External links
  public get externalLinksBody(): TableBody[] {
    const rows: TableBody[] = this.externalLinksData.map<TableBody>((item) => {
      return {
        url: item.url,
        total: item.total.toString()
      };
    });

    if (rows.length > 0) {
      rows.push({
        url: 'Total',
        total: this.externalLinksData.reduce((previousValue, currentValue) => {
          return previousValue + currentValue.total;
        }, 0)
      });
    }

    return rows;
  }

  // ---- Daily stats
  public get dailyStatsBody(): TableBody[] {
    const data: DailyStatsDataRow[] = [];

    // Sessions
    this.chartSessionData.forEach((item) => {
      item.data.forEach((itemDate) => {
        const dataItem = data.find((dataItem) => dataItem.date === itemDate.date);

        if (dataItem) {
          switch (item.text.toLowerCase()) {
            case 'desktop':
              dataItem.desktop.sessions += itemDate.value;
              break;

            case 'tablet':
              dataItem.tablet.sessions += itemDate.value;
              break;

            case 'mobile':
              dataItem.mobile.sessions += itemDate.value;
          }
        } else {
          data.push({
            date: itemDate.date,
            desktop: {
              sessions: item.text.toLowerCase() === 'desktop' ? itemDate.value : 0,
              registrations: 0
            },
            tablet: {
              sessions: item.text.toLowerCase() === 'tablet' ? itemDate.value : 0,
              registrations: 0
            },
            mobile: {
              sessions: item.text.toLowerCase() === 'mobile' ? itemDate.value : 0,
              registrations: 0
            }
          });
        }
      });
    });

    // Registrations
    this.chartRegistrationData.forEach((item) => {
      item.data.forEach((itemDate) => {
        const dataItem = data.find((dataItem) => dataItem.date === itemDate.date);

        if (dataItem) {
          switch (item.text.toLowerCase()) {
            case 'desktop':
              dataItem.desktop.registrations += itemDate.value;
              break;

            case 'tablet':
              dataItem.tablet.registrations += itemDate.value;
              break;

            case 'mobile':
              dataItem.mobile.registrations += itemDate.value;
          }
        } else {
          data.push({
            date: itemDate.date,
            desktop: {
              registrations: item.text.toLowerCase() === 'desktop' ? itemDate.value : 0,
              sessions: 0
            },
            tablet: {
              registrations: item.text.toLowerCase() === 'tablet' ? itemDate.value : 0,
              sessions: 0
            },
            mobile: {
              registrations: item.text.toLowerCase() === 'mobile' ? itemDate.value : 0,
              sessions: 0
            }
          });
        }
      });
    });

    return data
      .sort((a, b) => {
        return b.date - a.date;
      })
      .map<TableBody>((item) => {
        return {
          date: format(new Date(item.date), 'dd-MM-yyyy'),
          desktop: `${item.desktop.sessions} / ${item.desktop.registrations}`,
          tablet: `${item.tablet.sessions} / ${item.tablet.registrations}`,
          mobile: `${item.mobile.sessions} / ${item.mobile.registrations}`,
          total: `${item.desktop.sessions + item.tablet.sessions + item.mobile.sessions} / ${
            item.desktop.registrations + item.tablet.registrations + item.mobile.registrations
          }`
        };
      });
  }

  // ---- Google map
  public get googleMap(): IElementGoogleMap {
    return {
      type: 'google-map',
      properties: {
        markers: this.countryStatsData.map((item) => {
          return {
            position: {
              lng: item.lng,
              lat: item.lat
            },
            // icon: null,
            icon: 'https://files.cdn.leadfamly.com/vendor/platform/campaign/assets/images/gmap-transparent.png',
            html: `<div class="marker marker--${item.size}"><span class="marker__num">${item.sessions.formatted}%</span></div>`
          };
        }),
        zoom: 5,
        centerLat: this.countryStatsData[0] ? this.countryStatsData[0].lat : 56,
        centerLng: this.countryStatsData[0] ? this.countryStatsData[0].lng : 10
      }
    };
  }

  // -- Summary
  public get timeSpentAverage(): string {
    if (this.summaryStatsData) {
      const minutes = Math.floor(this.summaryStatsData.session_duration.average / 60);
      const seconds = Math.floor(this.summaryStatsData.session_duration.average % 60);

      return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    return '';
  }

  public get timeSpentHours(): string {
    if (this.summaryStatsData && this.summaryStatsData.session_duration.total) {
      const hours = this.summaryStatsData.session_duration.total / 60 / 60;
      return `${hours.toFixed(1)}`;
    }

    return '0';
  }

  public get espIntegrations(): CampaignIntegrationModel[] {
    return this.integrations?.filter((item) => item.resource.namespace === 'esp') ?? [];
  }

  public get flowPageSessionMap(): Record<number, string> {
    if (
      this.flowPageSessionsData &&
      this.flowPages &&
      this.flowPages[0] &&
      this.flowPageSessionsData[this.flowPages[0].resource.id]
    ) {
      const data: Record<number, string> = {};
      const firstFlowPageViews = this.flowPageSessionsData[this.flowPages[0].resource.id];

      for (const flowPageId in this.flowPageSessionsData) {
        if (Object.prototype.hasOwnProperty.call(this.flowPageSessionsData, flowPageId)) {
          const pageViews = this.flowPageSessionsData[flowPageId] ?? 0;

          data[Number(flowPageId)] =
            firstFlowPageViews && pageViews ? `${Math.floor(pageViews / (firstFlowPageViews / 100))}%` : '0%';
        }
      }

      return data;
    }

    return {};
  }

  public get headlineSize(): string {
    return getDevice() === 'mobile' ? '4' : '2';
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography.scss';

.stat-box-chart {
  width: 172px;
  height: 172px;
}

.info {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-small);

  &__sub {
    @include component-text-headline-small;
  }

  &__main {
    @include component-text-strong-underlined-large;

    text-overflow: ellipsis;
    overflow: hidden;
    width: 300px;
    max-width: 100%;
    white-space: nowrap;
  }

  &__help {
    @include component-text-headline-small;
    color: var(--text-color-help);
  }
}

.devices {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-size-small);

  &__item {
    display: flex;
    align-items: flex-start;
    gap: var(--gap-size-medium);

    &-color {
      width: 18px;
      height: 18px;

      &--desktop {
        background: var(--color-brand-secondary);
      }

      &--tablet {
        background: var(--color-brand-tertiary);
      }

      &--mobile {
        background: var(--color-brand-primary);
      }
    }

    &-text {
      display: flex;
      align-items: flex-start;
      gap: var(--gap-size-small);
      min-width: 120px;

      @include component-text-strong-large;
    }
  }
}

.funnel {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: var(--gap-size-large);
  overflow-x: auto;

  &__section {
    display: flex;
    flex-direction: row;
    gap: var(--gap-size-large);

    &-inner {
      position: relative;
    }

    &-card {
      z-index: 2;
      position: relative;
      background: var(--color-background-layer-1);
    }
  }

  &__flow-page {
    display: flex;
    flex-direction: column;
    gap: var(--gap-size-large);

    &-inner {
      position: relative;
    }

    &-card {
      z-index: 2;
      position: relative;
      background: var(--color-background-layer-1);
    }
  }

  &__decorator {
    &-inner {
      position: relative;
    }

    &-card {
      z-index: 2;
      position: relative;
      background: var(--color-background-layer-1);
    }
  }

  &__connector {
    position: absolute;

    &--left {
      right: 0;
      top: 50%;
      width: calc(100% + var(--gap-size-large));
      height: 3px;
      background-color: var(--color-grey-400);
      z-index: 1;
    }

    &--bottom {
      left: 50%;
      top: 0;
      height: calc(100% + var(--gap-size-large));
      width: 3px;
      background-color: var(--color-grey-500);
      z-index: 1;
    }
  }
}

::v-deep {
  .marker {
    display: block;
    width: 40px;
    height: 40px;
    background-color: #000;
  }

  .marker {
    width: 1.7rem;
    height: 1.7rem;
    line-height: 1.7rem;
    text-align: center;
    color: #fff;
    border-radius: 50%;
    background-color: #586f7c;
    box-sizing: initial;
    position: relative;
  }

  .marker:before {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    content: '';
    border-radius: 50%;
    top: 0;
    left: 0;
    transform: scale(1.3);
    background-color: rgba(88, 111, 124, 0.6);
  }

  .marker__num {
    position: relative;
  }

  .marker--xl {
    width: 3.7rem;
    height: 3.7rem;
    line-height: 3.7rem;
    background-color: #a51151;
  }

  .marker--xl:before {
    background-color: rgba(165, 17, 81, 0.6);
  }

  .marker--l {
    width: 3.2rem;
    height: 3.2rem;
    line-height: 3.2rem;
    background-color: #ea3282;
  }

  .marker--l:before {
    background-color: rgba(234, 50, 130, 0.6);
  }

  .marker--m {
    width: 2.7rem;
    height: 2.7rem;
    line-height: 2.7rem;
    background-color: #dc3545;
  }

  .marker--m:before {
    background-color: rgba(220, 53, 69, 0.6);
  }

  .marker--s {
    width: 2.2rem;
    height: 2.2rem;
    line-height: 2.2rem;
    background-color: #586f7c;
  }

  .marker--s:before {
    background-color: rgba(88, 111, 124, 0.6);
  }

  .marker--xs {
    background-color: #155724;
  }

  .marker--xs:before {
    background-color: rgba(21, 87, 36, 0.6);
  }
}
</style>
