<template>
  <div
    class="form-element no-drag"
    :class="[
      size ? `form-element--size-${size}` : undefined,
      { 'form-element--disabled': disabled, 'form-element--readonly': readonly }
    ]"
    :slot="$attrs.slot"
  >
    <div class="form-element__header" v-if="label || helpText || aiAssistant">
      <p-label v-if="label" :disabled.prop="disabled">{{ label }}</p-label>
      <p-help-text v-if="helpText" :disabled.prop="disabled" :inTooltip.prop="showHelpIcon">{{ helpText }}</p-help-text>
      <div class="form-element__header-toolbar" v-if="aiAssistant">
        <p-ai-assistant
          :modelValue="modelValue"
          :disabled="disabled"
          @update:modelValue="$emit('update:modelValue', $event)"
        />
      </div>
    </div>

    <div class="form-element__body">
      <p-tooltip v-if="error" :text="error" display="block" type="negative" :position="errorPosition">
        <slot></slot>
      </p-tooltip>
      <slot v-else></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  name: 'p-form-element',
  inheritAttrs: false
})
export default class FormElement extends Vue {
  @Prop({ type: String, required: false, default: undefined }) public readonly label?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly helpText?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly helpInTooltip!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly disabled!: boolean;
  @Prop({ type: String, required: false, default: undefined }) public readonly error?: string;
  @Prop({ type: Boolean, required: false, default: false }) public readonly readonly!: boolean;
  @Prop({ type: Boolean, required: false, default: false }) public readonly aiAssistant!: boolean;
  @Prop({ type: [String, Number], required: false, default: undefined }) public readonly modelValue!: string | number;

  @Prop({
    type: String as PropType<'extra-small' | 'small' | 'medium' | 'large' | 'extra-large'>,
    required: false,
    default: undefined,
    validator: (value: string) => {
      return ['extra-small', 'small', 'medium', 'large', 'extra-large'].includes(value);
    }
  })
  public readonly size?: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large';

  @Prop({
    type: String as PropType<'top' | 'right' | 'bottom' | 'left'>,
    default: 'right',
    validator: (value: string) => ['top', 'right', 'bottom', 'left'].includes(value)
  })
  public errorPosition!: 'top' | 'right' | 'bottom' | 'left';

  public get showHelpIcon() {
    return this.helpText && (this.helpInTooltip || this.helpText.length > 75);
  }
}
</script>

<style lang="scss" scoped>
@import '../../../scss/mixins/typography';

.form-element {
  width: 100%;

  &--size {
    &-extra-small {
      width: var(--field-width-extra-small);
    }

    &-small {
      width: var(--field-width-small);
    }

    &-medium {
      width: var(--field-width-medium);
    }

    &-large {
      width: var(--field-width-large);
    }

    &-extra-large {
      width: var(--field-width-extra-large);
    }
  }

  &__body {
    width: 100%;
    position: relative;

    &--error {
      margin-top: 3px;

      @include component-text-small;

      color: var(--field-color-border-negative);
    }
  }

  &__header {
    display: flex;
    gap: var(--gap-size-small);
    margin: 0 0 var(--gap-size-extra-small) 0;
    position: relative;

    &-toolbar {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
</style>
