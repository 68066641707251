<template>
  <div class="context-menu" ref="container">
    <div class="context-menu__activator" ref="activator" @click.stop="opened = !opened">
      <slot name="activator" />
    </div>

    <div class="context-menu__content" v-if="opened">
      <p-container gap-size="small" align-items="stretch">
        <slot name="default" />
      </p-container>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  inheritAttrs: false
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public readonly closeOnClick!: boolean;
  public opened = false;

  public mounted() {
    document.addEventListener('click', this.onClickOutside);
  }

  public beforeUnmount() {
    document.removeEventListener('click', this.onClickOutside);
  }

  public onClickOutside(event: MouseEvent) {
    // Ignore clicks on activator since it has it's own toggle function
    if (
      event.target instanceof Element &&
      this.$refs.activator instanceof Element &&
      this.$refs.activator.contains(event.target)
    ) {
      return;
    }

    // If it should close on click, then we should always close it when clicking anywhere
    if (this.opened && this.closeOnClick) {
      this.$nextTick(() => {
        this.opened = false;
      });
      return;
    }

    if (
      this.opened &&
      event.target instanceof Element &&
      this.$refs.container instanceof Element &&
      !this.$refs.container.contains(event.target)
    ) {
      this.opened = false;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../scss/mixins/typography';

.context-menu {
  position: relative;
  display: inline-block;

  &__activator {
    cursor: pointer;
  }

  &__content {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    min-width: 200px;
    padding: var(--base-size-75) var(--base-size-100) var(--base-size-75) var(--base-size-100);
    border: 1px solid var(--field-color-border-control);
    background: var(--field-color-background-control);
    box-shadow: var(--shadow-elevation-2);
  }
}
</style>
