<template>
  <menu-overlay :show="show" title="Search" @close-request="$emit('close-request')" :body-border="false">
    <p-table
      searchable
      searchable-autofocus
      pagination
      :header="[
        { id: 'id', label: 'ID', width: '80px', verticalAlign: 'middle', hasPadding: true },
        { id: 'name', label: 'Name', width: '350px', verticalAlign: 'middle', hasPadding: true },
        { id: 'type', label: 'Campaign type', width: '250px', verticalAlign: 'middle', hasPadding: true },
        { id: 'actions', verticalAlign: 'middle', hasPadding: true }
      ]"
      :body="campaignsBody"
      :total-items="campaigns?.meta?.total ?? 0"
      :skeleton-loader="loading"
      v-model="tableState"
    >
      <template #bodyCell="{ row, column, header }">
        <!-- Type -->
        <template v-if="header.id === 'type'">
          <div class="campaign-type" v-if="campaignTypes[row.type]">
            <p-icon :icon="campaignTypes[row.type].icon" /> {{ campaignTypes[row.type].name }}
          </div>
        </template>

        <!-- Name -->
        <template v-else-if="header.id === 'name'">
          <p-tooltip :text="column">
            <div class="campaign-name">{{ column }}</div>
          </p-tooltip>
        </template>

        <template v-else-if="header.id === 'actions'">
          <p-link :href="row.live_url" target="_blank">
            <p-button color-type="tertiary" size="small">Live URL</p-button>
          </p-link>

          <p-link :href="row.demo_url" target="_blank">
            <p-button color-type="tertiary" size="small">Demo URL</p-button>
          </p-link>

          <p-link :href="`/campaign/${row.type}/${row.id}/modify`">
            <p-button color-type="tertiary" size="small">Edit</p-button>
          </p-link>
        </template>

        <!-- Default -->
        <template v-else>{{ column }}</template>
      </template>
    </p-table>
  </menu-overlay>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MenuOverlay from './MenuOverlay.vue';
import { AppRequest } from '@/app_request';
import { CampaignResource, CampaignTypeResource } from '@/types/api/campaign';
import { PaginatedResult } from '@/types/api/generic';
import { TableBody, TableState } from '../../Table.vue';
import { debounce } from 'lodash-decorators';

interface CampaignTypeResourceMap {
  [key: string]: CampaignTypeResource;
}

@Component({
  components: {
    MenuOverlay
  }
})
export default class extends Vue {
  @Prop({ type: Boolean, required: false, default: true }) public readonly show!: boolean;

  public loading = true;
  public campaignTypes: CampaignTypeResourceMap = {};
  public campaigns: PaginatedResult<CampaignResource> | null = null;

  public tableState: TableState = {
    sorting: {
      column: '',
      direction: 'none'
    },
    searchTerm: '',
    itemsPerPage: 10,
    currentPage: 1,
    bulkSelections: []
  };

  @Watch('show')
  public onShow() {
    if (this.show) {
      this.search();
    }
  }

  @Watch('tableState.searchTerm')
  @debounce(1500)
  public onSearchTermChange() {
    this.tableState.currentPage = 1;
    this.searchDebounces();
  }

  @Watch('tableState.currentPage')
  public onPageChange() {
    this.searchDebounces();
  }

  @Watch('tableState.itemsPerPage')
  public onItemsPerPageChange() {
    this.searchDebounces();
  }

  @debounce(25)
  public searchDebounces() {
    this.search();
  }

  public async search() {
    this.loading = true;

    if (Object.keys(this.campaignTypes).length === 0) {
      const campaignTypesResponse = (await AppRequest.get<{ data: CampaignTypeResource[] }>('/api/v1/campaign-type'))
        .data.data;
      const campaignTypes: CampaignTypeResourceMap = {};

      campaignTypesResponse.forEach((campaignType) => {
        campaignTypes[campaignType.alias] = campaignType;
      });

      this.campaignTypes = campaignTypes;
    }

    this.campaigns = (
      await AppRequest.get<PaginatedResult<CampaignResource>>('/api/v1/campaign', {
        params: {
          ...(this.tableState.searchTerm && { search: this.tableState.searchTerm }),
          ...(this.tableState.currentPage && { page: this.tableState.currentPage }),
          ...(this.tableState.itemsPerPage && { per_page: this.tableState.itemsPerPage }),
          ...(!this.tableState.searchTerm && { display: 'active' }),
          sort: ['created_on:desc']
        }
      })
    ).data;

    this.loading = false;
  }

  public get campaignsBody(): TableBody[] {
    return (
      this.campaigns?.data.map<TableBody>((campaign) => {
        return {
          ...campaign
        };
      }) ?? []
    );
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';

::v-deep {
  .campaign-name {
    @include component-text-strong;

    color: var(--text-color-headline);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .campaign-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--gap-size-small);
  }
}
</style>
