<template>
  <div class="tabs" :class="{ 'tabs--no-items': items.length === 0 }">
    <div class="tabs__container">
      <div class="tabs__header">
        <div class="tabs__headline" v-if="headline">
          <p-headline v-device-desktop size="3">{{ headline }}</p-headline>
          <p-headline v-device-mobile size="4">{{ headline }}</p-headline>

          <div class="tabs__headline-sub">
            <p-paragraph typography="text-body-large" v-if="subheadline">{{ subheadline }}</p-paragraph>
          </div>
        </div>

        <div class="tabs__build-campaign">
          <p-link v-device-desktop v-if="buildCampaignLink" :href="buildCampaignLink" target="_blank"
            ><p-button size="medium" type="cta">Build campaign</p-button></p-link
          >

          <p-link v-device-desktop v-if="viewCampaignLink" :href="viewCampaignLink" target="_blank"
            ><p-button size="medium" type="cta">Demo Url</p-button></p-link
          >
        </div>
      </div>

      <ul class="tabs__navigation" v-if="items.length > 0">
        <li
          class="tabs__navigation-item"
          v-for="tab in items"
          :key="tab.href"
          :class="{
            'tabs__navigation-item--active': tab.active,
            'tabs__navigation-item--no-access': tab.access === false
          }"
        >
          <a v-if="tab.access === false" href="#" @click.prevent="showNoAccessModal = true">{{ tab.text }}</a>
          <p-link v-else :href="tab.href">{{ tab.text }}</p-link>
        </li>

        <li class="tabs__navigation-item tabs__navigation-item--fast-track" v-if="fastTrackLink">
          <p-link :href="fastTrackLink" target="_blank">
            <p-button color-type="tertiary" size="medium">Fast track</p-button>
          </p-link>
        </li>
      </ul>
    </div>

    <ul class="tabs__sub-navigation" v-if="items.length > 0 && subItems.length > 0">
      <li
        class="tabs__sub-navigation-item"
        v-for="tab in subItems"
        :key="tab.href"
        :class="{
          'tabs__sub-navigation-item--active': tab.active,
          'tabs__sub-navigation-item--no-access': tab.access === false
        }"
      >
        <a v-if="tab.access === false" href="#" @click.prevent="showNoAccessModal = true">{{ tab.text }}</a>
        <p-link v-else :href="tab.href">{{ tab.text }}</p-link>
      </li>
    </ul>

    <p-modal-plain
      :show.prop="showNoAccessModal"
      headline="Unauthorized Access Alert"
      @close-request="showNoAccessModal = false"
      body-padding
    >
      <div class="unauthorized">
        <p-row justify-content="center">
          <p-column size="extra-large">
            <p-container align-items="center" gap-size="large">
              <p-headline size="2">Your Role Does Not Grant Access</p-headline>

              <p-paragraph typography="component-text-helptext">
                We're sorry, but it seems you do not have the necessary permissions to access this page.<br /><br />
                Please contact your administrator to request the appropriate access or explore other areas of the
                application that are available to your role
              </p-paragraph>
            </p-container>
          </p-column>
        </p-row>
      </div>

      <p-button slot="footer" color-type="tertiary" @click="showNoAccessModal = false">Close</p-button>
    </p-modal-plain>
  </div>
</template>

<script lang="ts">
import { PropType } from 'vue';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { TabsNavItem } from './types';

@Component({
  name: 'nav-tabs'
})
export default class extends Vue {
  @Prop({ type: String, required: false, default: undefined }) public readonly headline?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly subheadline?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly buildCampaignLink?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly viewCampaignLink?: string;
  @Prop({ type: String, required: false, default: undefined }) public readonly fastTrackLink?: string;

  @Prop({ type: Array as PropType<TabsNavItem[]>, required: false, default: () => [] })
  public readonly items!: TabsNavItem[];

  @Prop({ type: Array as PropType<TabsNavItem[]>, required: false, default: () => [] })
  public readonly subItems!: TabsNavItem[];

  public showNoAccessModal = false;

  @Watch('headline', { immediate: true })
  @Watch('subItems', { immediate: true, deep: true })
  public updateTitle() {
    const activeSubItem = this.subItems.find((tab) => tab.active);
    const subTitle = activeSubItem?.text || this.subheadline || '';

    if (this.headline) {
      document.title = subTitle ? `Admin | ${this.headline} - ${subTitle}` : `Admin | ${this.headline}`;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/mixins/typography';
@import '../../../../scss/mixins/devices';

.tabs {
  width: 100%;
  padding-top: var(--base-size-200);
  background: var(--navigation-color-layer-base);

  &--no-items {
    padding-bottom: var(--base-size-300);
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--gap-size-small);

    color: var(--navigation-color-text-default);

    --text-color-default-inverted: var(--color-base-800);
    --text-color-default: var(--navigation-color-text-default);
    --text-color-headline: var(--navigation-color-text-default);
  }

  &__build-campaign {
    --text-color-default: var(--text-color-default-inverted);
  }

  &__header {
    display: flex;
    width: 100%;
    padding: var(--base-size-75) var(--base-size-300);
    justify-content: space-between;
    align-items: center;
  }

  &__headline {
    display: flex;
    flex-direction: column;
    padding: 3px 0;

    &-sub {
      --text-color-default: var(--color-base-300);
    }
  }

  &__navigation {
    display: flex;
    width: 100%;
    padding: 0px var(--base-size-300);
    align-items: center;
    gap: var(--gap-size-small);
    background: var(--navigation-color-layer-1);

    &-item {
      display: flex;
      align-items: flex-start;
      gap: var(--gap-size-small);
      border-bottom: 3px solid transparent;

      &--fast-track {
        margin-left: auto;
      }

      &:not(.tabs__navigation-item--fast-track) {
        > a {
          display: inline-block;
          padding: var(--base-size-200);
          text-decoration: none;

          @include component-text-headline;
          color: var(--navigation-color-text-secondary);

          &:hover {
            color: var(--navigation-color-text-default);
          }
        }

        &.tabs__navigation-item--active {
          border-bottom: 3px solid var(--color-brand-primary);

          a {
            @include component-text-strong;
            color: var(--navigation-color-text-default);
          }
        }

        &.tabs__navigation-item--no-access {
          opacity: 0.5;
          cursor: not-allowed;

          > a {
            cursor: not-allowed;
          }
        }
      }
    }
  }

  &__sub-navigation {
    display: flex;
    width: 100%;
    padding: 0 var(--base-size-300);
    align-items: center;
    gap: var(--gap-size-small);
    background: var(--navigation-color-layer-2);

    &-item {
      display: flex;
      align-items: flex-start;
      gap: var(--gap-size-small);
      border-bottom: 3px solid transparent;

      > a {
        display: inline-block;
        padding: var(--base-size-200);
        text-decoration: none;

        @include component-text-headline;
        color: var(--navigation-color-text-secondary);

        &:hover {
          color: var(--navigation-color-text-default);
        }
      }

      &--active {
        border-bottom: 3px solid var(--navigation-color-text-default);

        a {
          @include component-text-strong;
          color: var(--navigation-color-text-default);
        }
      }

      &--no-access {
        opacity: 0.5;
        cursor: not-allowed;

        > a {
          cursor: not-allowed;
        }
      }
    }
  }
}

@media print {
  .tabs {
    display: none;
  }
}

@include for-mobile-only {
  .tabs {
    &__navigation {
      overflow-x: auto;

      &-item {
        white-space: nowrap;
      }
    }

    &__sub-navigation {
      overflow-x: auto;

      &-item {
        white-space: nowrap;
      }
    }
  }
}

.unauthorized {
  text-align: center;
}
</style>
