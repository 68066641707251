<template>
  <p-section
    :headline.prop="element.properties?.title"
    :description.prop="element.properties?.description"
    :disabled.prop="element.properties?.disabled"
    :headline-size.prop="headlineSize"
    :gap-size.prop="element.properties?.gapSize"
    :collapsible.prop="element.properties?.collapsible"
    :collapsibleId.prop="element.key"
    :collapsedInitially.prop="element.properties?.collapsedInitially"
  >
    <layout-element v-for="child in toolbar" :element="child" :key="child.key" slot="toolbar" />

    <layout-element v-for="child in children" :element="child" :key="child.key" />
  </p-section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IElementSection } from '@/interfaces/element';
import { getElementsForRender } from '@/utility';

@Component({
  name: 'layout-element-section'
})
export default class extends Vue {
  @Prop() public element!: IElementSection;

  public get headlineSize() {
    if (this.element.properties?.heading !== undefined) {
      return String(this.element.properties.heading);
    }

    return undefined;
  }

  public get children() {
    return getElementsForRender(this.element.children ?? []);
  }

  public get toolbar() {
    return getElementsForRender(this.element.toolbar ?? []);
  }
}
</script>
